import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.blue,
      dark: colors.blue,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.blue,
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    // error: will use the default color
  },
});

export default theme;
