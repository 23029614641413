import { combineReducers } from 'redux';
import user from './user';
import globalFilters from './globalFilters';
import avatars from './avatars';


const reducers = {
  user,
  globalFilters,
  avatars,
};

export default combineReducers(reducers);
