
// todo  pages without // ^ has no tests

const roots = {
  root: '/',
  app: '/app',
  company: '/app/company',
  users: '/app/users',
  common: '/app/common',

  superAdmin: {
    root: '/app/super-admin',
    providersManagement: '/app/super-admin/providers-management',
    permissions: '/app/super-admin/permissions',
  },
  operator: {
    root: '/app/operator',
    collaborators: '/app/operator/collaborators',
    operators: '/app/operator/operators',
    chargers: '/app/operator/chargers',
    transactions: '/app/operator/transactions',
    smartCharging: '/app/operator/smart-charging',
    labels: '/app/operator/labels',
  },
  provider: {
    root: '/app/provider',
    clients: '/app/provider/clients',
    transactions: '/app/provider/transactions',
    rfidCards: '/app/provider/rfid-cards',
    labels: '/app/provider/labels',
  },
};

export default {
  app: {
    root: roots.app,
    common: {
      root: roots.common,
      dashboard: `${roots.common}/dashboard`, // ^
      myProfile: `${roots.common}/my-profile`, // ^
    },
    superAdmin: {
      root: roots.superAdmin.root,
      providersManagement: {
        root: roots.superAdmin.providersManagement,
        providersList: `${roots.superAdmin.providersManagement}/providers-list`, // ^
        createProvider: `${roots.superAdmin.providersManagement}/create-provider`, // ^
        allClients: `${roots.superAdmin.providersManagement}/all-clients`, // ^
      },
      permissions: {
        root: roots.superAdmin.permissions,
        roles: `${roots.superAdmin.permissions}/roles`, // ^
      },
    },
    operator: {
      root: roots.operator.root,
      collaborators: {
        root: roots.operator.collaborators,
        collaboratorList: `${roots.operator.collaborators}/collaborator-list`, // ^
      },
      operators: {
        root: roots.operator.operators,
        groupStructure: `${roots.operator.operators}/group-structure`, // ^
        createOperator: `${roots.operator.operators}/create-operator`, // ^
        invitation: id => `${roots.operator.operators}/${id}/invitation`, // ^
      },
      chargers: {
        root: roots.operator.chargers,
        networks: `${roots.operator.chargers}/networks`, // ^
        connectors: `${roots.operator.chargers}/connectors`, // ^
        createNetwork: `${roots.operator.chargers}/create-network`, // ^

        connectorsStatus: status => `${roots.operator.chargers}/connectors${status}`, // ^
        actionsHistoryConnectors: uuid => `${roots.operator.chargers}/actions-history-connectors/${uuid}`, // ^
        actionsHistoryNetworks: uuid => `${roots.operator.chargers}/actions-history-networks/${uuid}`, // ^
        connectorDetails: uuid => `${roots.operator.chargers}/connector-details/${uuid}`, // ^
        networkDetails: uuid => `${roots.operator.chargers}/network-details/${uuid}`, // ^
      },
      transactions: {
        root: roots.operator.transactions,
        transactionsList: `${roots.operator.transactions}/transactions-list`, // ^
        operatorDetails: id => `${roots.operator.transactions}/operator-transaction-details/${id}`, // ^
      },
      smartCharging: {
        root: roots.operator.smartCharging,
        chargingProfilesList: `${roots.operator.smartCharging}/charging-profiles-list`, // ^
        createChargingProfile: `${roots.operator.smartCharging}/create-charging-profile`, // ^
      },
      labels: {
        root: roots.operator.labels,
        labelsList: `${roots.operator.labels}/labels-list`, // ^
        labelsPairing: `${roots.operator.labels}/label-pairing`, // ^
      },
    },
    provider: {
      root: roots.provider.root,
      clients: {
        root: roots.provider.clients,
        clientsList: `${roots.provider.clients}/clients-list`, // ^
        addClient: `${roots.provider.clients}/add-client`, // ^
        clientProfile: id => `${roots.provider.clients}/client-profile/${id}`, // ^
        assignOperatorRoles: id => `${roots.provider.clients}/client-profile/${id}/operator-roles`,
        assignProviderRoles: id => `${roots.provider.clients}/client-profile/${id}/provider-roles`,
      },
      transactions: {
        root: roots.provider.transactions,
        transactionsList: `${roots.provider.transactions}/transactions-list`, // ^
        providerDetails: id => `${roots.provider.transactions}/provider-transaction-details/${id}`, // ^
      },
      rfidCards: {
        root: roots.provider.rfidCards,
        assignRfidToUser: `${roots.provider.rfidCards}/assign-rfid-to-user`, // ^
        rfidsList: `${roots.provider.rfidCards}/rfids-list`, // ^
      },
      labels: {
        root: roots.provider.labels,
        labelsList: `${roots.provider.labels}/labels-list`, // ^
      },
    },
  },
  forgotPassword: '/forgot-password', // ^
  invitation: '/invitation', // ^
  login: '/login', // ^
  logout: '/logout', // ^
  passwordReset: confirm => `/password-reset/${confirm}`, // ^
};
