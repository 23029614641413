import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import ErrorMessage from '@/components/Page/ErrorMessage';


/**
 *  Wrapper for every page
 *  it adds title to page and displays error page if needed
 *
 */

class Page extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    metaData: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
    error: PropTypes.instanceOf(Error),
  };

  static defaultProps = {
    children: null,
    error: null,
  };

  render() {
    const {

      children,
      error,
      metaData,
    } = this.props;

    if (error) {
      return (
        <>
          <Helmet title={metaData.title} />
          <ErrorMessage error={error} />
        </>
      );
    }

    return (
      <>
        <Helmet title={metaData.title} />
        {children}
      </>
    );
  }
}


export default Page;
