import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FlatButton from '@/components/inputs/FlatButton';
import urls from '@/constants/urlsStructure';
import styles from './errorMessage.scss';


class ErrorMessage extends Component {
  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    error: PropTypes.instanceOf(Error),
  };

  render() {
    const { error, t } = this.props;

    return (
      <div>
        <h1
          className={styles.errorPageTitle}
          cy-selector="errorMessageTitle"
        >
          {t('componentsShared:anErrorHasOccurred')}
        </h1>
        <div>
          <div className={styles.errorDetailsSectionIntroduction}>
            {t('componentsShared:errorDetailsSectionIntroduction')}
          </div>
          <div className={styles.errorDetails}>
            <div className={styles.errorDetailsTitle}>
              {error.message}
            </div>
            <div>
              {error.combineMessage || ''}
            </div>
          </div>
        </div>
        <div className={styles.errorActionButtonsContainer}>
          <Link to="/">
            <FlatButton
              text={t('componentsShared:goBackToDashboard')}
              styling="black"
            />
          </Link>
          <Link to={urls.logout}>
            <FlatButton
              text={t('componentsShared:logout')}
              styling="black"
            />
          </Link>
          <FlatButton
            text={t('componentsShared:reload')}
            styling="black"
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('componentsShared')(ErrorMessage);
