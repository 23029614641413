const SET_USER = 'USER/SET_USER';
const CLEAR_USER = 'USER/CLEAR_USER';
const SET_EMAIL = 'USER/SET_EMAIL';
const SET_ERROR_MESSAGE = 'USER/SET_ERROR_MESSAGE';
const LOGGED_OUT_REDIRECT = 'USER/LOGGET_OUT_REDIRECT';
const SET_NAME = 'USER/SET_NAME';
const SET_COMPANY_ADMIN = 'USER/SET_COMPANY_ADMIN';

const initialState = {
  email: '',
  confirmed: null,
  first_name: '',
  is_active: null,
  last_name: '',
  phone_number: '',
  roles: [],
  permissions: [],
  tree_group: null,
  username: '',
  uuid: '',

  name: '',
  isCompanyAdmin: '',


  errorMessage: '', // message: '',
  redirectToLoginFlag: false,


};

export default function user(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.userObject,
      };
    case CLEAR_USER:
      return initialState;
    case SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_COMPANY_ADMIN:
      return {
        ...state,
        isCompanyAdmin: action.bool,
      };

    // to be moved or removed
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case LOGGED_OUT_REDIRECT:
      return {
        ...state,
        flag: action.flag,
      };
    default:
      return state;
  }
}


export function setUser(userObject) {
  return { type: SET_USER, userObject };
}

export function clearUser() {
  return { type: CLEAR_USER };
}
