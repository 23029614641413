import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import CircularLoadingIndicator from '@/components/LoadingIndicators/CircularLoadingIndicator';
import wrapLoadingIndicator from '@/components/loadablePage/wrapLoadingIndicator';
import urls from '@/constants/urlsStructure';


const LoadingComponent = wrapLoadingIndicator(CircularLoadingIndicator);

const MainApp = loadable({
  loader: () => import('./app'),
  loading: LoadingComponent,
});

const Auth = loadable({
  loader: () => import('./auth'),
  loading: LoadingComponent,
});


const MainRoots = () => (
  <Switch>
    <Redirect from="/" exact to={urls.app.common.dashboard} />
    <Route
      path={urls.app.root}
      component={MainApp}
    />
    <Route
      path={urls.root}
      component={(props) => <Auth {...props} />}
    />
  </Switch>
);


export default MainRoots;
