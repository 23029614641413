import axios from 'axios';
import MD5 from 'md5.js';
import { parseError } from '@/apiUtilities/parseError';


const simpleRequestsAxiosInstance = axios.create({
  timeout: 30 * 1000,
  withCredentials: false,
});


export async function fetchGravatar(email) {
  const emailHash = new MD5().update(email).digest('hex');
  const url = `https://www.gravatar.com/avatar/${emailHash}.jpg?d=404`;
  try {
    const response = await simpleRequestsAxiosInstance.get(url, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    if (!error.response || error.response.status !== 404) {
      parseError(error);
    }
    return null;
  }
}
