import { fetchGravatar } from '@/apiUtilities/fetchGravatar';


export const SET_USER_AVATAR = 'AVATARS/SET_USER_AVATAR';

const initialState = {};

export default function avatars(state = initialState, action) {
  switch (action.type) {
    case SET_USER_AVATAR:
      return {
        ...state,
        [action.uuid]: action.url,
      };
    default:
      return state;
  }
}

export function setAvatar(uuid, url) {
  return { type: SET_USER_AVATAR, uuid, url };
}

export function getAvatarForUser(user) {
  return async dispatch => {
    dispatch(setAvatar(user.uuid, null));
    if (user.avatar) {
      dispatch(setAvatar(user.uuid, user.avatar));
    } else if (user.email) {
      const imageBlob = await fetchGravatar(user.email);
      if (imageBlob) {
        const blobURL = URL.createObjectURL(imageBlob);
        dispatch(setAvatar(user.uuid, blobURL));
      }
    }
  };
}
