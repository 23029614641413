import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import styles from './flatButton.scss';


interface IProps {
  className?: string,
  styling?: 'grey' | 'black' | 'salmon' | 'blue' | 'blackText',
  text: string,
  disabled?: boolean,
  defaultWidth?: boolean,
  lower?: boolean,
  onClick?: () => void,
  fullWidth?: boolean,
}

const FlatButton = ({
  className,
  text,
  styling,
  disabled,
  defaultWidth,
  lower,
  onClick,
  fullWidth,
  ...otherProps
}: IProps) => (
  <Button
    variant="outlined"
    onClick={onClick}
    className={classNames(
      className,
      styles.flatButton,
      styling,
      {
        lower,
        [styles.defaultWidth]: defaultWidth,
      },
    )}
    disabled={disabled}
    fullWidth={fullWidth}
    {...otherProps}
  >
    {text}
  </Button>
);

FlatButton.defaultProps = {
  className: '',
  styling: 'blue',
  disabled: false,
  defaultWidth: true,
  lower: false,
  onClick: () => null,
  fullWidth: false,
};

export default FlatButton;
