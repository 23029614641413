export const SET_GROUP_FILTER = 'FILTERS/SET_GROUP_FILTER';


const initialState = {
  groups: null,
};

export default function globalFilters(state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_FILTER:
      return {
        ...state,
        groups: action.groups,
      };
    default:
      return state;
  }
}

export function setGroupFilter(groups) {
  return { type: SET_GROUP_FILTER, groups };
}
