import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

const backendOptions = {
  loadPath: '/static/locales/{{lng}}/{{ns}}.json',

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2

  // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
  allowMultiLoading: false,

  // allow cross domain requests
  crossDomain: false,

  // allow credentials on cross domain requests
  withCredentials: true,
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    debug: false,
    backend: backendOptions,
    fallbackLng: 'en', // use en if detected lng is not available
    ns: 'common',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
      // useSuspense: false,
    },

    detection: { // LanguageDetector options
      // order and from where user language should be detected
      order: ['cookie', 'navigator'],
      lookupCookie: 'language',
    },
  });

export default i18n;
