import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './circularLoadingIndicator.scss';

/** CircularLoadingIndicator should center itself in parent */
class CircularLoadingIndicator extends Component {
  static propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    size: '60px',
  };

  render() {
    const { size, ...otherProps } = this.props;
    const readySize = typeof size === 'string' ? size : `${size}px`;

    return (
      <div className={styles.spinnerContainer}>
        <svg
          className={styles.spinner}
          width={readySize}
          height={readySize}
          viewBox="0 0 60 60"
          {...otherProps}
        >
          <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="30" cy="30" r="28" />
        </svg>
      </div>
    );
  }
}

export default CircularLoadingIndicator;
