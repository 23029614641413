import React from 'react';
import Helmet from 'react-helmet';
// For storybook it has to be a relative path.
import extendedColors from '../../constants/colors';


// for every color variable in /constants/colors.js string like this:
// '--variableName: #hexcolor;' is added to variable  variables
let variables = '';
Object.keys(extendedColors).forEach(colorName => {
  variables += `--${colorName}: ${extendedColors[colorName]};`;
});
/* language=CSS */
variables = `:root {${variables}}`;

// And then string with all variables is placed in a style tag in the head tag
const CssVariablesProvider = (props) => (
  <>
    <Helmet>
      <style>
        {variables}
      </style>
    </Helmet>
    {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
    {props.children}
  </>
);

export default CssVariablesProvider;
